import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import News from "../../src/components/home-page/recent-writing";
import About from "../../src/components/home-page/about";
import Artists from "../../src/components/home-page/artists";
import Contact from "../../src/components/home-page/contact";
import Music from "../../src/components/home-page/music";
import Hero from "../../src/components/home-page/hero";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="a string quintet with a twist" mdxType="SEO" />
    <Hero mdxType="Hero" />
    <News mdxType="News" />
    <About mdxType="About" />
    <Artists mdxType="Artists" />
    <Music mdxType="Music" />
    <Contact mdxType="Contact" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      